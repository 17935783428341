'use strict'

const menuToggle = document.querySelector(`.menu-toggle`);
const mainMenu = document.querySelector(`.main-menu`);

(() => {
  if (menuToggle) {
    const onClickMenuToggle = () => {
      menuToggle.classList.toggle(`open`);
    };
    const onClickMenu = () => {
      if (menuToggle.classList.contains(`open`)) {
        menuToggle.classList.remove(`open`);
      }
    };
    menuToggle.addEventListener(`click`, onClickMenuToggle);
    mainMenu.addEventListener(`click`, onClickMenu);
  }
})();
